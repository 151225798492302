import Checker from "@/utils/Checker";
import {
	installmentModeEnum,
	mockCashierChecker,
	xlTypeEnum
} from "../../constants/index";
import {
	transferSignUpJudgeRecommend
} from '@/api/customer'
import {
	accAdd
} from "@/utils/mathMethod";
export default {
	methods: {
		// 通过天翼userId获取钉钉userId
		async getDDUserIdByTyUserId(id) {
			// eslint-disable-next-line
			return new Promise(async resolve => {
				if (Checker.isUndefined(id) || Checker.isNull(id) || !id) return resolve('');
				// 命中测试数据
				let hitTestData = mockCashierChecker.find(item => item.dingtalkUserId === id)
				if (hitTestData && hitTestData.dingtalkUserId) return resolve(hitTestData.dingtalkUserId)
				if (!this._cacheDDUserIdMap) {
					this._cacheDDUserIdMap = {}
				}
				if (this._cacheDDUserIdMap[id]) resolve(this._cacheDDUserIdMap[id]);
				const res = await this.$fetch('getUserInfoByTyUserId', {
						id
					})
					.catch(err => {
						err.msg && this.$message.warning(err.msg)
					})
				if (res && res.code === 200 && res.data && res.data.dingtalkUserId) {
					// 缓存当前已经获取的钉钉id
					this._cacheDDUserIdMap[id] = res.data.dingtalkUserId
					resolve(res.data.dingtalkUserId)
				} else {
					resolve('')
				}
			})
		},
		getTyUser() {
			if (this.getTyUserInfo && this.getTyUserInfo.id) return this.getTyUserInfo
			return JSON.parse(localStorage.getItem("tyUserInfo")) || {}
		},
		// 转班升班报名单新增商品变更时触发校验，更新转介绍字段
		async transferSignUpJudgeRecommend(goods) {
			//客户编号 this.signUpForm.customerCode
			const {
				result
			} = await transferSignUpJudgeRecommend({
				customerId: this.signUpForm.customerCode || this.studentInfo.customerCode || '',
				tyProductTypeId: goods.productId
			})
			if (result) {
				Object.assign(this.transactionInfo, {
					isRecommand: result.isRecommand,
					isRecommandName: result.isRecommand == 1 ? '是' : '否',
					teacherRecommandName: result.teacherRecommandName, //转介绍老师名字
					ncTeacherRecommandUserId: result.ncTeacherRecommandUserId, //转介绍老师id
          transIntroduceType: result.isRecommand == 1?result.transIntroduceType:''
				})
				this.teachOrder.qkcStatus = result.isRecommand
				this.teachOrder.qkcTeacherId = result.ncTeacherRecommandUserId //转介绍老师id
				this.teachOrder.qkcTeacherName = result.teacherRecommandName //转介绍老师名字
			}
		},
		// 存放原始成交信息
		cacheOriginTransactionInfo(transactionInfo) {
			let _transactionInfo = {
				...transactionInfo
			}
			this._transactionInfo = {
				isRecommand: _transactionInfo.isRecommand,
				isRecommandName: _transactionInfo.isRecommand == 1 ? '是' : '否',
				teacherRecommandName: _transactionInfo.teacherRecommandName,
				ncTeacherRecommandUserId: _transactionInfo.ncTeacherRecommandUserId,
			}
		},
		// 恢复原始转介绍
		recoverTransactionInfo() {
			const transactionInfo = this._transactionInfo
			if (transactionInfo) {
				Object.assign(this.transactionInfo, {
					isRecommand: transactionInfo.isRecommand,
					isRecommandName: transactionInfo.isRecommand == 1 ? '是' : '否',
					ncTeacherRecommandUserId: transactionInfo.ncTeacherRecommandUserId,
					teacherRecommandName: transactionInfo.teacherRecommandName,
				})
				Object.assign(this.teachOrder, {
					qkcStatus: transactionInfo.isRecommand,
					qkcTeacherId: transactionInfo.ncTeacherRecommandUserId,
					qkcTeacherName: transactionInfo.teacherRecommandName,
				})
			}
		},
		// 清除当前表单校验
		clearValidate() {
			this.$refs.form && this.$refs.form.clearValidate()
		},
		/**判断是否是手机号**/
		isPhoneNumber(tel) {
			var reg = /^1\d{10}$/;
			return reg.test(tel);
		},
		//（退费、转班）处理多条单的收支项目
		setOneDropOut(index) {
			let oneDropOut = {
				orderId: this.classInfo[index].orderId + "" || "",
				goodsId: this.classInfo[index].goodsId || "",
				goodsName: this.classInfo[index].goodsName || "",
				goodsSpecName: this.classInfo[index].goodsSpecName || "",
				goodsSpecId: this.classInfo[index].goodsSpecId || "",
				signCount: this.classInfo[index].signCount || 0,
				lessonCount: this.classInfo[index].lessonCount || 0,
				spendedMoney: this.classInfo[index].spendedMoney || 0,
				receivableMoney: this.classInfo[index].receivableMoney || 0, //单表应收金额
				receivedMoney: this.classInfo[index].receivedMoney || 0, //单表已收金额
				deductionMoney: 0, //核定扣费金额
				refundMoney: this.classInfo[index].refundMoney || 0,
				inoutProjects: [],
				underlineViewType: this.classInfo[index].underlineViewType || 0,
				onlineViewType: this.classInfo[index].onlineViewType || 0,

				ineffectiveMonth: this.classInfo[index].ineffectiveMonth || 0,
				effectiveMonth: this.classInfo[index].effectiveMonth || 0,
				underlineSpendMoney: this.classInfo[index].underlineSpendMoney || 0,
				underlineMonthSpendMoney: this.classInfo[index].underlineMonthSpendMoney || 0,
				onlineSpendMoney: this.classInfo[index].onlineSpendMoney || 0,
				onlineMonthSpendMoney: this.classInfo[index].onlineMonthSpendMoney || 0,
				sortNo: this.classInfo[index].sortNo,
				productId: this.classInfo[index].productId,
				goodsTypeId: this.classInfo[index].goodsTypeId,

			};

			let deductionsList = this.dropOutTableList[index]; //现在不用转换了，直接是数组，先取第一个能提交再调整
			let deductionMoney = 0
			for (let i in deductionsList) {
				deductionsList[i].inoutProjectName =
					deductionsList[i].orderInoutProjectName;
				// delete deductionsList[i].orderInoutProjectName //delete对象属性后竟然
				deductionMoney += isNaN(+deductionsList[i].deductionMoney) ? 0 : +deductionsList[i].deductionMoney
			}
			oneDropOut.inoutProjects = deductionsList;
			oneDropOut.deductionMoney = accAdd(deductionMoney, 0, 2)
			if (!isNaN(+oneDropOut.deductionMoney)) {
				oneDropOut.deductionMoney = +oneDropOut.deductionMoney
			}

			return oneDropOut;
		},
		//更新信息到蓝鲸
		async doLanJingData() {
			let signUpForm = this.signUpForm;
			let trackType = `${this.loginUser.trackType}`;
			let form = {
				mobile: signUpForm.stuMoblie,
				realName: signUpForm.name,
				idCard: signUpForm.idCard,
				//商机 0表示学历，1表示财经
				raceTrack: trackType === '1' ? '0' : trackType === '2' ? '1' : trackType,
				//推送商机，固定值0，表示关闭
				goodsChance: 0,
				//注册渠道
				registerChannelId: trackType === "1" ? "cjxq" : trackType === '2' ? "zkzsd" : 'cjxq'
			}
			if (signUpForm.userId) {
				form.userId = signUpForm.userId;
			}
			let requestName = form.userId ? "updateUserInfo" : "saveUserInfo";

			let result = await this.$fetch(requestName, form);
			if (!form.userId && result) {
				//更新userId
				this.signUpForm.userId = result.data;
				// alert(`更新userId: ${this.signUpForm.userId}`)
			}
			return !!this.signUpForm.userId
		},
		// 判断是否为贷款单
		isLoanOrder(){
			return [installmentModeEnum.onlineLoan, installmentModeEnum.offlineLoan].includes(this.installmentMode)
		},
		// 需求地址http://10.0.98.1/zentao/task-view-3430.html
		// 筛选出specificationNameId1、specificationNameIds2数据供wxTradeOrderDiscountVOList使用
		// 几种情况的处理方式 http://yapi.hqbis.com/project/296/interface/api/16950
		getSpecificationNameIdInfo(){
			let ospecificationNameId1 = []
			let ospecificationNameIds2 = []
			let ospecificationTypeId = []//类型id
			let specificationNameId1 = []
			let specificationNameIds2 = []
			// 学历处理过程
			if(this.currentGoods.xlType == xlTypeEnum.wj || this.currentGoods.xlType == xlTypeEnum.ck){
				// 学历属于第一点的情况，不需要处理
				// const majorId = this.graduactionForm.enrollingMajorIdList[0]
				// this.ckSingle.specificationPriceDataSource.map(spItem =>{
				// 	if(spItem.majorId == majorId){
				// 		console.log('[成考、网教]', spItem);
				// 		spItem.specificationNameId1 && ospecificationNameId1.push(spItem.specificationNameId1);
				// 		spItem.specificationNameId2 && ospecificationNameIds2.push(spItem.specificationNameId2);
				// 		spItem.specificationTypeId && ospecificationTypeId.push(spItem.specificationTypeId);//类型id
				// 	}
				// })
			}else{
				// 其他产品
				this.specPriceArr.map(spItem =>{
					const hit = this.specIdArr.find(specId => spItem.id == specId)
					if(hit){
						console.log('[hit]', spItem);
						spItem.specificationNameId1 && ospecificationNameId1.push(spItem.specificationNameId1);
						spItem.specificationNameId2 && ospecificationNameIds2.push(spItem.specificationNameId2);
						if(this.specTypeArr && this.specTypeArr[0] && this.specTypeArr[0].specificationNameArr){
							const specTypeHit = this.specTypeArr[0].specificationNameArr.find(spNameItem => spNameItem.id == spItem.specificationNameId1)
							if(specTypeHit){
								// 找出一级选中的类型id
								ospecificationTypeId.push(specTypeHit.specificationTypeId)
							}
						}
					}
				})
			}
			ospecificationNameId1 = [...new Set(ospecificationNameId1)]
			ospecificationNameIds2 = [...new Set(ospecificationNameIds2)]
			ospecificationTypeId = [...new Set(ospecificationTypeId)]
			specificationNameIds2 = ospecificationNameIds2
			specificationNameId1 = ospecificationNameId1
			// 没有二级，表示是一级单选或多选
			if(!ospecificationNameIds2.length){
				specificationNameId1 = !isNaN(+ospecificationTypeId[0]) ? +ospecificationTypeId[0] : ''//传入类型id
				specificationNameIds2 = ospecificationNameId1//传入规格id
			}
			
			if(Checker.isArray(specificationNameId1) && ( !specificationNameId1.length || !specificationNameId1[0])){
				specificationNameId1 = ''
			}
			if(Checker.isArray(specificationNameId1) && specificationNameId1[0]){
				specificationNameId1 = specificationNameId1[0]
			}
			console.log('[ospecificationTypeId]', ospecificationTypeId, ' [specificationNameId1]', specificationNameId1, ' [specificationNameIds2]', specificationNameIds2);
			return {
				specificationNameId1,
				specificationNameIds2
			}
		}
	}
}