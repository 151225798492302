'use strict'
/**
 * 加法add(0.1, 0.2)  // 得到结果：0.3
 * @param {*} arg1 
 * @param {*} arg2 
 * @param {Number | null} afterCount 可选 小数点后保留几位
 */
export function accAdd(arg1, arg2, afterCount = null) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2))
    n = (r1 >= r2) ? r1 : r2;
    if (afterCount !== null) {
        n = afterCount
    }
    return ((arg1 * m + arg2 * m) / m).toFixed(n);
}
/**
 * 减法： sub(1, 0.9)    // 得到结果：0.1
 * @param {*} arg1 
 * @param {*} arg2 
 * @param {Number | null} afterCount 可选 小数点后保留几位
 */
export function accSub(arg1, arg2, afterCount = null) {
    var re1, re2, m, n;
    try {
        re1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        re1 = 0;
    }
    try {
        re2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        re2 = 0;
    }
    m = Math.pow(10, Math.max(re1, re2));
    n = (re1 >= re2) ? re1 : re2;
    if (afterCount !== null) {
        n = afterCount
    }
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}